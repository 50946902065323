import React from 'react';
import { useTheme, useMediaQuery, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TimelineDesktop from './timelineDesktop';
import TimelineMobile from './timelineMobile';
import * as styles from './style.module.scss';

const Timeline = ({ intl: { formatMessage }, data }) => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <article className={styles.timeline__container}>
      <Typography variant="h3" className={styles.timeline__container__title}>
        {formatMessage({ id: 'businessHowWorkTitle' })}
      </Typography>
      <Typography variant="h6" className={styles.timeline__container__subtitle}>
        {formatMessage({ id: 'businessHowWorkSubtitle' })}
      </Typography>
      {matchesMdDown ? (
        <TimelineMobile data={data} />
      ) : (
        <TimelineDesktop data={data} />
      )}
    </article>
  );
};

Timeline.propTypes = {
  intl: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default injectIntl(Timeline);
