// extracted by mini-css-extract-plugin
export var background_images = "style-module__background_images___Ujl+V";
export var body_background = "#f8f9fa";
export var card = "style-module__card___GAXnl";
export var chip = "style-module__chip___FrfGZ";
export var container = "style-module__container___VSTni";
export var content = "style-module__content___wvjSo";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "style-module__full_img___mWdfP";
export var full_imgSmall = "style-module__full_img--small___ppbjx";
export var gray_21 = "style-module__gray_21___h9KZ9";
export var image_container = "style-module__image_container___cc3FN";
export var image_item = "style-module__image_item___Wi+eL";
export var lg = "1200px";
export var logo = "style-module__logo___GTMWt";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "style-module__primary___-Ko0w";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var timeline__container = "style-module__timeline__container___2j-O8";
export var timeline__container__subtitle = "style-module__timeline__container__subtitle___yALMX";
export var timeline__container__title = "style-module__timeline__container__title___uVIFg";
export var title = "style-module__title___i8cxI";
export var typography_h1 = "style-module__typography_h1___UN7j7";
export var typography_h2 = "style-module__typography_h2___OQD03";
export var typography_h3 = "style-module__typography_h3___QITVJ";
export var white = "#fff";
export var white_button = "style-module__white_button___sdMA1";
export var xl = "1536px";
export var xxl = "2500px";