import { LOCALES } from '../locales';

const locale = {
  [LOCALES.EN.value]: {
    // HOME
    // HEADER
    home: 'Home',
    aboutUs: 'About Us',
    businessTalent: 'Business Area',
    professionalsJob: 'Professionals Area',
    contactUs: 'Contact Us',

    lookingFor: 'Looking for',
    iTTalents: 'IT Talents',
    forYourBusiness: 'for Your Business',
    weProvideTalent: 'We Provide IT Talent When You Need It Most',
    findExcellentProfessionals:
      'Finding excellent IT professionals is the key to building successful teams. We have the talent you need.',
    tellUs: 'Tell Us',
    softwareEngineersInSpain: 'Software Engineers in Spain',
    annualDemandForTechnicians: 'Annual Demand for Technicians Worldwide',
    iTJobs: 'IT Jobs by 2024 Globally',
    findingExcellentProfessionals:
      'Finding excellent professionals is key to building',
    greatTeams: 'great teams',
    enterOurAreaForCompanies: 'Enter Our Area for Companies',
    ourDifferentiatingElement:
      'Our differentiating element is our competitive rates and the technical excellence of our IT professionals. Discover more about our services.',
    ourDifferentiatingElement2:
      'Our team is composed of IT sector experts. We guarantee the best profiles through thorough technical assessments.',
    searchAndSelection: 'IT Talent Search and Selection',
    weValidateInOurTeam:
      'We specialize in IT talent search and selection. We validate candidates to ensure they meet your requirements.',
    knowledgeToTheBest: 'Knowledge of the Best Talents',
    connectProfessionals:
      'We connect professionals with companies that value their skills. Our experience allows us to form solid teams with the necessary talent and seniority.',
    appropriateFollow:
      'Our team consists of IT sector experts. We guarantee the best profiles through thorough technical assessments.',
    weAreAConsolidatedCompany:
      'We are a consolidated company with over 15 years of experience in the Technology and Recruitment sector.',
    readMore: 'Read More',

    youTellUsWhatYouNeed: 'You Tell Us What You Need',
    youTellUsWhatYouNeedTitle: 'Efficient Solutions for IT Talent Selection',
    youTellUsWhatYouNeedSubtitle:
      'We combine experience and advanced technology to optimize IT talent selection. From identifying your needs to implementing AI solutions, we offer a complete service to connect you with the best professionals.',
    talentSelectionProcessQuick:
      'We make your IT talent selection process quick and simple. Just tell us what you need and we will connect you with the ideal candidates, saving you time and money.',
    weOptimizeYourTime: 'We Optimize Your Time',
    yourTimeIsMoney:
      'Your time is money. At Connecting Tech People, we handle the complex part of the talent search. We select, filter, and test candidates at no cost to you.',
    codeLovers: 'We Are Code Lovers',
    ourPassionForCode:
      'At Connecting Tech People, our passion for code drives us to find the best IT professionals. We manage the most challenging aspects of the talent search, including selection, filtering, and technical testing, all at no cost to you.',
    weSimplifyProcess: 'We Simplify the Process',
    simplifyingTheTalentAcquisitionProcess:
      'Simplifying the talent acquisition process is our priority. At Connecting Tech People, we handle the selection, filtering, and technical testing of candidates, making it easier for you to find the perfect fit at no cost.',
    weProvideAIProcess: 'We Provide AI Processes',
    providingAnAIProcess:
      'Our expertise in AI processes means we implement and manage advanced AI systems to perform specific tasks or solve complex problems, enhancing efficiency and effectiveness in your projects.',

    doYouWantToBoostYourCareer: 'Do You Want to Boost Your Career?',
    joinUs:
      'Join us and we will help you find the perfect project. We value your talent and guide you towards professional success.',
    weAreTheAgency: 'We Are the Agency You Need',
    jobPool: 'IT Job Pool',
    weManage:
      'We manage the demand for IT professionals from various companies, offering numerous opportunities where your skills can shine. Our extensive network ensures you find the perfect fit for your expertise and career aspirations.',
    weAccompanyYou: 'We Accompany You',
    weHelpYou:
      'We support you in defining and enhancing your professional profile. Our team offers expert guidance to refine your resume, craft compelling cover letters, and prepare you for successful job interviews.',
    orientation: 'Professional Guidance',
    ourOrientationServices:
      'Our orientation services help you navigate your career path effectively. We provide tailored advice on improving your resume, cover letter, and interview skills, empowering you to achieve your professional goals with confidence.',
    businessArea: 'Business Area',
    weAreYourAlly:
      'We are your ally in finding IT talents for your team. Our methodology is simple and efficient. Discover the advantages of working with us.',
    professionalsArea: 'Professionals Area',
    weBoostYour:
      'We boost your career by connecting you with the best opportunities in the IT sector. Join our network of professionals and take your career to the next level.',
    joinUsProfessionalArea: 'Join Us',
    weAreCommitted:
      'We are committed to helping IT professionals advance their careers. Our streamlined methodology connects you with top companies seeking your skills. Discover the benefits of partnering with us and take the next step in your professional journey.',
    anyTimeSupport: 'Support and Help at Any Time',
    // FOOTER
    connectWithUs: 'Connect With Us!',
    ourYearsOfExperience:
      'Our years of experience and the satisfaction of our clients endorse us.',
    fullName: 'Name',
    phoneNumber: 'Phone Number',
    email: 'Email',
    message: 'Message',
    iAgreeProvide:
      'I agree to provide my data so that they can manage my request for information.',
    privacyPolicy: 'Privacy Policy',
    submit: 'Submit',
    responseContact: 'We will contact you shortly',
    rejectContact: 'Please try again',
    sent: 'Sent',
    tryAgain: 'Please try again',

    weBringTheYears:
      'We bring years of global experience and stamina to guide our clients through new and often disruptive realities.',
    usefullLinks: 'Useful Links',
    termsAccept: 'You must accept the terms and conditions.',
    professionalsWeAreTitle: 'We are the agency you need',
    professionalsWeAreSubtitle:
      'Thanks to our direct contact with companies, we know the most demanded requirements for each job.',
    professionalsWeAreEnterTitle: 'Enter to our community area',
    professionalsWeAreEnterText:
      'Our differentiating element are our excellent rates and the technical excellence of our professionals. We invite you to learn more about our service offer. ',
    professionalsWeAreSearchTitle: 'Search and Selection',
    professionalsWeAreSearchText:
      'We validate in our team and in our pool of professionals if one meets your requirements or we turn to the search and selection of the perfect candidate.',
    professionalsWeAreKnowledgeTitle: 'Knowledge to the test',
    professionalsWeAreKnowledgeText:
      'Appropriate follow up can help us to identify misunderstandings and answer questions, or make further assessments and adjust treatments.',
    termsCondition: 'Terms & Conditions',
    allRightsReserved: 'All Rights Reserved',
    invalidEmail: 'Invalid email',
    invalidPhone: 'Invalid phone',
    invalidMessage: 'Tell us what you need',
    invalidRecaptcha: 'Please verify that you are not a robot',
    required: 'Required',
    getInTouch: 'Get in Touch',

    // policy
    politics: 'LinkedIn Sidebar Privacy Policies of Connecting Tech People',
    infoGeneral: 'General Information',
    textGeneral:
      'LinkedIn Sidebar enhances productivity by allowing quick access to LinkedIn profiles directly from a browser sidebar.',
    useData: 'Data Usage',
    textData:
      'We do not collect or store user personal information. The extension runs scripts locally on your device and does not transmit personal data.',
    allowsExtension: 'Extension Permissions',
    activeTab: 'Used to interact with the active tab.',
    contextMenus: 'Allows adding items to the Chrome context menu.',
    storage: 'Used to save user preferences.',
    tabs: 'Necessary for tab management during interaction with the extension.',
    sidePanel: 'Allows displaying LinkedIn content in a sidebar.',
    // 404
    pageNotFound: 'Page Not Found',
    soSorry: 'We are sorry 😔, this page does not exist',
    goHome: 'Go to Home',

    // cookies
    titleCookies: 'COOKIES POLICY',
    textCookies1:
      'This cookie policy is an integral part of the Legal Notice and Data Protection Policy of',
    textCookies2:
      '(hereinafter the website). Accessing and browsing the website, or using its services, implies acceptance of the terms and conditions set out in the Legal Notice and Data Protection Policy.',
    textCookies3: 'To facilitate your navigation of the website,',
    textCookies4: '(hereinafter, the provider) with registered office at',
    textCookies5:
      'with CIF B01638196, informs you that it uses cookies or other similar functionality files (hereinafter, cookies).',
    textCookies6:
      'In any case, we inform you that the provider is responsible for the cookies and the processing of the data obtained through own and third-party cookies, deciding on the purpose, content, and use of the information collected.',
    subtitleCookies1: 'What is a cookie?',
    textCookies7:
      "Cookies are files that contain small amounts of information that are downloaded to the user's device when visiting a web page. Their main purpose is to recognize the user each time they access the website, allowing for improved quality and a better use of the website.",
    textCookies8:
      "Cookies are essential for the functioning of the Internet; they cannot damage the user's equipment/device and, if activated in your browser settings, help to identify and resolve possible operational errors of the website. Use of Cookies by the provider",
    textCookies9:
      "By accessing the Website, you expressly accept the use of these types of Cookies on your devices. If you disable Cookies, your navigation on the Website may not be optimal and some of the Website's utilities may not function correctly.",
    textCookies10:
      'Specifically, the provider is using Cookies for the purposes outlined below. If in the future the provider uses others to offer more and better services, the user will be informed.',
    textCookies11:
      'The Website also uses performance cookies from Google Inc. These cookies collect anonymous information about how visitors use a website, for example, which pages visitors view most frequently, among others.',
    textCookies12:
      'These cookies do not collect identifying information about the visitor. All information collected by cookies is aggregated and, therefore, anonymous. They are only used to improve the functioning of a website. For more information on the functioning and disabling of cookies, you can visit the Google Privacy Center and the Google Analytics Opt-out Browser Add-on pages. Regarding the possible cookies implemented on this website, referring to Google Analytics, they are:',
    textCookies13:
      'Cookies Name Purpose Information Google Analytics __utma __utmb __utmc __utmz Collect anonymous information about web navigation to know the origin of visits and other statistical data. Google Analytics Cookie Usage on Websites Connecting Tech People cannot control nor is responsible for the content and accuracy of the terms and conditions and privacy policies of WordPress.com or Google Analytics. Both determine the purpose of the processing and use of the information captured by them, at all times, as well as the functioning and duration of the cookies, and, according to the information provided by both, the user can avoid capturing that information by rejecting the installation of cookies through the configuration of their browser.',
    textCookies14:
      "The Website may use necessary cookies to remember the user's navigation preferences during its validity (e.g. recognizing whether the user is using a mobile device or a computer, for the purpose of customizing the user interface). The cookie usage notice tool installed on the website may use a session cookie to remember the user's preferences regarding the information provided about this mechanism, to comply with the information obligation on cookie use.",
    textCookies15:
      "When loading the Website and accessing it, 'database storage' and 'local storage' may occur to facilitate faster, more agile, and easier navigation.",
    textCookies16:
      'Finally, third-party cookies may be installed if the user uses the tools to share any of the website content on different social networks. These cookies are installed by the social networks themselves where the content is shared. To learn more about them, it is recommended to visit the pages of these social network tools.',
    subtitleCookies2: 'User settings to avoid Cookies',
    textCookies17:
      'In compliance with current legal regulations, we provide you with the information that allows you to configure your Internet browser(s) to maintain your privacy and security in relation to Cookies. Therefore, we provide the information and links to the official support sites of the main browsers so you can decide whether to accept the use of Cookies.',
    textCookies18:
      'Thus, you can block Cookies through the configuration tools of the browser, or configure your browser to notify you when a server wants to save a Cookie:',
    textCookies19:
      'It will be understood that the user accepts the use of cookies if they continue browsing this website without previously deactivating them.',

    // Cookie Consent
    cookieConsentTitle: 'This website uses cookies.',
    cookieConsentDescription:
      'We use cookies to improve the functionality of the site and analyze our traffic. We will only use cookies if you allow us by clicking "Accept cookies". You can also choose which cookies you want to allow.',
    cookieConsentLearnMore: 'Learn more about our cookies',
    cookieConsentAccept: 'Accept cookies',
    cookieConsentDecline: 'Decline',
    cookieConsentPersonalize: 'Personalize',
    cookieConsentSave: 'Save',

    // Cookie types
    cookieTypeEssential: 'Essential',
    cookieTypeEssentialDescription:
      'Essential cookies are necessary for the proper functioning of the site. The site cannot function properly without them.',
    cookieTypeAnalytics: 'Google Analytics',
    cookieTypeAnalyticsDescription:
      'Google Analytics is a statistical tool from Google that allows measuring the audience of the website.',
    cookieTypeTagManager: 'Google Tag Manager',
    cookieTypeTagManagerDescription:
      'Google Tag Manager is a tag management system that allows you to quickly and easily update tags and code snippets on your website.',

    // About Us
    aboutUsTitle1: 'We Provide Talent When',
    aboutUsTitle2: 'You Need It Most',
    aboutUsSubtitle:
      'Finding excellent professionals is key to building great teams',
    aboutUsCultureTitle: 'Our Culture',
    aboutUsCultureSubtitle:
      'We are one team composed of our clients, partners, and ourselves.',
    aboutUsCultureCard1Title: 'Our Mission',
    aboutUsCultureCard1Content:
      'Connect expert professionals with the best companies in the IT sector. We focus on providing highly qualified talent that drives innovation and business growth.',
    aboutUsCultureCard2Title: 'Our Vision',
    aboutUsCultureCard2Content:
      'To be the leading provider in the IT industry, facilitating the connection between professionals and companies. We aspire to be the first choice for organizations seeking technical excellence and proven experience.',
    aboutUsCultureCard3Title: 'Our Values',
    aboutUsCultureCard3Content:
      'Commitment, responsibility, integrity and transparency. Foster an ethical and collaborative work environment that promotes professional development and client satisfaction.',
    aboutUsHistoryTitle: 'Our Agency History',
    aboutUsHistoryContent:
      'We make your talent selection process quick, easy, and simple. Just tell us what you need, and we will connect you with candidates that fit your needs, saving you time and money.',
    aboutUsHistory1Title: 'Experience in the Technology Sector',
    aboutUsHistoryYears: '+15 years',
    aboutUsHistoryExperience: 'Company experience',
    aboutUsHistory1Paragraph:
      'We are a consolidated company with over 15 years of experience. During this time, we have gained expertise in the technology and recruitment sector, allowing us to build solid teams with great talent and the necessary seniority to achieve quality results in record time.',
    aboutUsHistory2Title: 'We Are Also Experts in the Sector',
    aboutUsHistory2Paragraph1:
      'At Connecting Tech People, in addition to being specialists in recruitment and selection services, we are also experts in the sector.',
    aboutUsHistory2Paragraph2:
      'We understand the industry and know that what we do will have a significant impact on the business world. That is why we have a highly qualified team with the necessary professionals to carry out our processes quickly and effectively.',
    aboutUsProfessionalsTitle: 'You Can Find Our Professionals At',
    testimonial: 'Testimonials',
    aboutUsReviewsTitle: 'Feedback from our clients',
    aboutUsReviewsContent1:
      'Our clients trust us to find the best professionals in the technology sector. Here we share some of their experiences with Connecting Tech People.',
    aboutUsReviewsContent2:
      'Working with Connecting Tech People has been a transformative experience for our company. Their ability to find highly qualified talent tailored to our needs has significantly improved our productivity and efficiency. I highly recommend your services.',
    aboutUsReviewsContent3:
      'Connecting Tech People has not only provided us with excellent professionals, but has also demonstrated a deep understanding of the technology sector. Thanks to the support of your team, we have been able to complete critical projects in record time. Their commitment to quality is unmatched.',
    aboutUsReviewsContent4:
      'We have collaborated with Connecting Tech People on several occasions and they have always exceeded our expectations. Their selection process is rigorous and effective, which ensures that we only work with the best talents on the market. Their team is professional and very accessible.',

    // Business

    businessTalentTitle1: 'Are you looking for IT talents for',
    businessTalentTitle2: 'your team?',
    businessTalentSubtitle: 'We provide top IT Talent when you need it most',
    fillTheForm: 'Fill the Form',

    businessTalentWhyWorkTitle: 'Why work with us',
    businessTalentWhyWorkContent:
      'We make your IT talent selection process quick, easy, and efficient. Just tell us what you need, and we will connect you with candidates that match your requirements, saving you time and money.',
    businessTalentWhyWork1Paragraph1:
      'At Connecting Tech People, we reach the most in-demand technological profiles and the highest professional standards. These professionals can be part of your team for the long term or for a specific period that suits you best. Our service is especially useful for companies that prefer to avoid direct hiring.',
    businessTalentWhyWork1Paragraph2:
      'We foster strategic alliances with our clients, generate professional development opportunities for our team, and contribute positively to our community.',
    businessTalentWhyWork1Paragraph3:
      'We are part of this labor market, so we know first-hand the latest trends and needs of the sector, and we adapt to them.',
    businessTalentWhyWork2Title: "We're experts in technology",
    businessTalentWhyWork2Paragraph1:
      'Our AI matching platform, developed in-house, significantly enhances the quality of hires. We use advanced algorithms and machine learning to match job openings with the most qualified candidates, ensuring precise and efficient selection.',
    businessTalentWhyWork2Paragraph2:
      'The AI-based talent-matching platform we have created not only matches job openings with suitable candidates but also optimizes the selection process. This allows our clients to quickly find the talent they need, reducing the time and costs associated with recruitment.',
    businessTalentWhyWork2Paragraph3:
      'For professionals, our AI platform offers a personalized experience, connecting them with opportunities that best align with their skills and aspirations. This not only improves candidate satisfaction but also increases retention and long-term success in their new positions.',
    businessTalentBrandsTitle: 'Technologies we use in Connecting Tech People',

    // Business / Recommended Section
    businessRecommendationTitle: 'Why Choose Our Services',
    businessRecommendationSubtitle: 'Discover the benefits of working with us',

    businessRecommendationSaveTimeTitle: 'Save time',
    businessRecommendationSaveTime:
      'Candidate search and selection takes time; we handle it for you, commitment-free.',

    businessRecommendationSafeSelectionTitle: 'Safe selection',
    businessRecommendationSafeSelection:
      "Instead of sifting through countless resumes, we'll send you only candidates that fit your needs.",

    businessRecommendationTechnicalValidationTitle: 'Technical validation',
    businessRecommendationTechnicalValidation:
      "We ensure the candidates meet your required skill level through our team's technical validation.",

    businessRecommendationInterestedCandidatesTitle: 'Interested candidates',
    businessRecommendationInterestedCandidates:
      'We introduce candidates to your company. You only interview those genuinely interested. Your time is valuable!',

    businessRecommendationSimplifyProcessesTitle: 'Simplify processes',
    businessRecommendationSimplifyProcesses:
      "You'll receive a single invoice at the end of the month for the services provided. We handle candidate payments.",

    businessRecommendationWithoutPermanenceTitle: 'Without permanence',
    businessRecommendationWithoutPermanence:
      'Pay only for the time you need the resource, without long-term commitments increasing your expenses.',

    businessRecommendationPayForServiceTitle: 'Pay for service',
    businessRecommendationPayForService:
      'Avoid the commitment of long-term hiring. Pay at the end of the month for the days worked.',

    businessRecommendationCompetitiveRatesTitle: 'Competitive rates',
    businessRecommendationCompetitiveRates:
      'Our rates are highly competitive, leading to significant savings for our clients.',

    // Business / Who Work

    businessHowWorkTitle: 'How we work',
    businessHowWorkSubtitle:
      'At Connecting Tech People we work intensely and meticulously in the recruitment of talents in order to connect our clients with their ideal candidate.',

    businessHowWorkStep1Title: 'Tell us your requirements',
    businessHowWorkStep1Content:
      'Fill out the web form with your requirements, and we will call you to clarify details. Our goal is to understand your specific needs to offer you the best candidates.',

    businessHowWorkStep2Title: 'Active search and selection process',
    businessHowWorkStep2Content:
      'Our team of experts will evaluate the candidates selecting the most suitable ones. We use AI to ensure they meet your requirements, include them in our team and present them to you.',

    businessHowWorkStep3Title: 'Candidate presentation',
    businessHowWorkStep3Content:
      'We will send you the relevant data of the selected candidate and the conditions of the offer that match your needs. Our process ensures that you only receive candidates who meet your expectations.',

    businessHowWorkStep4Title: 'Interview with the professional',
    businessHowWorkStep4Content:
      'We organize a meeting so you can personally meet the candidate and assess their suitability. This step is crucial to ensure the candidate fits your company culture.',

    businessHowWorkStep5Title: 'Technical test',
    businessHowWorkStep5Content:
      'Selected candidates will undergo a technical test to demonstrate their knowledge. This evaluation ensures that candidates have the necessary skills for the position.',

    businessHowWorkStep6Title: 'AI process',
    businessHowWorkStep6Content:
      'We implement and optimize algorithms and machine learning techniques to perform specific tasks or solve particular problems, improving the efficiency of the selection process.',

    // Professionals
    professionalJobTitle1: 'Accelerate Your IT Career',
    professionalJobTitle2: 'with Connecting Tech People',
    professionalJobSubtitle:
      'Join our network of top tech talent. We match skilled IT professionals with cutting-edge projects and industry-leading companies. Elevate your career in software development, data science, cybersecurity, and more.',

    professionalJobConnectTitle: 'Connect with Tech Opportunities',
    professionalJobConnectContent:
      "Our AI-powered talent matching system quickly connects you with ideal tech roles. Share your skills and preferences, and we'll find opportunities that align with your expertise and career goals.",
    professionalJobConnect1Paragraph1:
      'As a leading IT talent agency, we specialize in placing skilled professionals in environments where their technical abilities and soft skills are valued and can thrive.',
    professionalJobConnect1Paragraph2:
      'We streamline your job search by involving you in carefully selected opportunities that match your professional profile, increasing your chances of landing the perfect tech role.',
    professionalJobConnect1Paragraph3:
      'Our innovative, in-house developed AI management system for IT professionals and clients offers significant advantages. It efficiently processes a high volume of job openings and candidate profiles, allowing us to provide faster responses, conduct more in-depth personal interviews, and minimize administrative tasks.',

    // Professionals / How we work

    professionalJobWhoWorkTitle: 'How we work',
    professionalJobWhoWorkSubtitle:
      'At Connecting Tech People we work intensely and meticulously in the recruitment of talents in order to connect our clients with their ideal candidate.',

    professionalJobHowWorkStep1Title: 'Tell us about yourself',
    professionalJobHowWorkStep1Content:
      'Complete our online form to help us get to know you better. We will call you to clarify details and understand your professional goals, what you are looking for in a project, and your needs. We want to find the perfect opportunity for you!',

    professionalJobHowWorkStep2Title: 'Let’s get to know your profile',
    professionalJobHowWorkStep2Content:
      'We will arrange a meeting to understand your expectations and ensure that the opportunity aligns with what you are looking for in your career. We want you to feel comfortable and supported at every step of the process.',

    professionalJobHowWorkStep3Title: 'AI search process',
    professionalJobHowWorkStep3Content:
      'We use advanced AI to analyze your skills and experience. This way, we can identify the best opportunities that fit you and present you to our clients as a highly qualified candidate.',

    professionalJobHowWorkStep4Title: 'Technical test',
    professionalJobHowWorkStep4Content:
      'We offer you the opportunity to showcase your skills in a technical test. This is your chance to stand out and demonstrate your level of expertise, which helps position you as the ideal candidate for the project.',

    professionalJobHowWorkStep5Title: 'Presentation of opportunities',
    professionalJobHowWorkStep5Content:
      'We will send you detailed information about the vacancies that best match your profile and expectations. Our goal is to present you with only projects that motivate you and where you can develop your full potential.',

    professionalJobHowWorkStep6Title: 'Hiring and onboarding',
    professionalJobHowWorkStep6Content:
      'When the project, team, and conditions meet your expectations, we sign the contract. We want you to feel secure and comfortable at every step of the process, providing you with transparency and continuous support for your professional development.',

    // SEO
    seoTitle: 'Connecting Tech People - Talent When You Need It Most',
    seoDescription:
      'Connecting Tech People provides top IT talent to build great teams. With over 15 years of experience, we specialize in recruitment and selection services in the technology sector.',
    seoKeywords:
      'IT talent, recruitment, technology sector, software engineers, IT professionals, staff augmentation, talent acquisition',
    seoRobots: 'index, follow',
    seoCanonical: 'https://www.connectingtechpeople.com',
    seoImage: 'https://www.connectingtechpeople.com/images/seo-image.jpg',
    seoAuthor: 'Connecting Tech People',
    seoPublisher: 'Connecting Tech People',

    // Open Graph
    ogImage: 'https://www.connectingtechpeople.com/images/og-image.jpg',

    // Twitter
    twitterCard: 'summary_large_image',
    twitterImage:
      'https://www.connectingtechpeople.com/images/twitter-image.jpg',

    // About Us
    aboutUsSeoTitle: 'About Us - Connecting Tech People',
    aboutUsSeoDescription:
      'Connecting Tech People is a consolidated company with over 15 years of experience in the Technological and Recruitment sector. We build solid teams with great talent and seniority to achieve quality results.',
    aboutUsSeoKeywords:
      'IT talent, recruitment, technology sector, software engineers, IT professionals, staff augmentation, talent acquisition, expert professionals, business growth',
    aboutUsOgUrl: 'https://www.connectingtechpeople.com/about-us',

    // SEO Business Area
    businessAreaSeoTitle: 'Business Area - Connecting Tech People',
    businessAreaSeoDescription:
      'Connecting Tech People provides top IT talent to build great teams. With over 15 years of experience, we specialize in recruitment and selection services in the technology sector.',
    businessAreaSeoKeywords:
      'IT talent, recruitment, technology sector, software engineers, IT professionals, staff augmentation, talent acquisition',
    businessAreaOgUrl: 'https://www.connectingtechpeople.com/business',

    // SEO Professionals Area
    professionalsAreaSeoTitle: 'Professionals Area - Connecting Tech People',
    professionalsAreaSeoDescription:
      'Connecting Tech People offers exciting career opportunities for IT professionals. With our extensive network and industry expertise, we connect top talent with leading companies in the technology sector. Join us to take your career to the next level.',
    professionalsAreaSeoKeywords:
      'IT jobs, technology careers, software engineer positions, IT professional opportunities, career growth, talent placement, job search, IT recruitment',
    professionalsAreaOgUrl:
      'https://www.connectingtechpeople.com/professionals',

    // Contact
    contactTitle1: 'Get in Touch',
    contactTitle2: 'with Us',
    contactSubtitle:
      'Join us and we will help you find the professional you need for your business. <br/> Connect with us and discover how we can help you grow with IT talent of quality.',

    contactInfo1Title: 'Our Email',
    contactInfo2Title: 'Chat To Support',
    contactInfo3Title: 'Call Us',
    contactInfo3Subtitle: 'Mon - Fri from 8:00 AM to 8:00 PM',
  },
};

export default locale;
