import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import * as styles from './timelineMobile.module.scss';

const TimelineMobile = ({ intl: { formatMessage }, data }) => {
  return (
    <div className={styles.steps}>
      {data.map((item, index) => (
        <div className={styles.steps__step} key={index}>
          <div className={styles.steps__step__icon_container}>
            <div className={styles.steps__step__icon_container__number}>
              {index + 1}.
            </div>
            <div className={styles.steps__step__icon_container__step_icon}>
              {item.mobileImage}
            </div>
          </div>
          <div className={styles.steps__step__content}>
            <h3>{formatMessage({ id: item.title })}</h3>
            <p>{formatMessage({ id: item.content })}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

TimelineMobile.propTypes = {
  intl: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default injectIntl(TimelineMobile);
