// extracted by mini-css-extract-plugin
export var background_images = "timelineMobile-module__background_images___hqh8J";
export var body_background = "#f8f9fa";
export var card = "timelineMobile-module__card___pGRm1";
export var chip = "timelineMobile-module__chip___1W+w9";
export var container = "timelineMobile-module__container___vfdo6";
export var content = "timelineMobile-module__content___Y6tCr";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "timelineMobile-module__full_img___cTO4W";
export var full_imgSmall = "timelineMobile-module__full_img--small___wzlPI";
export var gray_21 = "timelineMobile-module__gray_21___2V6Ku";
export var image_container = "timelineMobile-module__image_container___nAhRB";
export var image_item = "timelineMobile-module__image_item___fs9Se";
export var lg = "1200px";
export var logo = "timelineMobile-module__logo___h73dN";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "timelineMobile-module__primary___Au3zW";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var steps__step = "timelineMobile-module__steps__step___iRsBN";
export var steps__step__content = "timelineMobile-module__steps__step__content___rHxSZ";
export var steps__step__icon_container = "timelineMobile-module__steps__step__icon_container___Nqlle";
export var steps__step__icon_container__number = "timelineMobile-module__steps__step__icon_container__number___xa1dM";
export var steps__step__icon_container__step_icon = "timelineMobile-module__steps__step__icon_container__step_icon___BbbuD";
export var title = "timelineMobile-module__title___OwB-0";
export var typography_h1 = "timelineMobile-module__typography_h1___FflID";
export var typography_h2 = "timelineMobile-module__typography_h2___aQSmj";
export var typography_h3 = "timelineMobile-module__typography_h3___EgPiE";
export var white = "#fff";
export var white_button = "timelineMobile-module__white_button___2litK";
export var xl = "1536px";
export var xxl = "2500px";