// extracted by mini-css-extract-plugin
export var background_images = "timelineDesktop-module__background_images___lKqZ6";
export var body_background = "#f8f9fa";
export var card = "timelineDesktop-module__card___+k6dX";
export var chip = "timelineDesktop-module__chip___8gmrF";
export var container = "timelineDesktop-module__container___6J3p5";
export var content = "timelineDesktop-module__content___rv+0c";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "timelineDesktop-module__full_img___SpwTW";
export var full_imgSmall = "timelineDesktop-module__full_img--small___shyNL";
export var gray_21 = "timelineDesktop-module__gray_21___DxWTR";
export var image_container = "timelineDesktop-module__image_container___9--nc";
export var image_item = "timelineDesktop-module__image_item___DvtAB";
export var lg = "1200px";
export var logo = "timelineDesktop-module__logo___UbpZ+";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "timelineDesktop-module__primary___+1QSO";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var timeline = "timelineDesktop-module__timeline___UZ8tI";
export var timeline__img = "timelineDesktop-module__timeline__img___RxOUN";
export var timeline__step = "timelineDesktop-module__timeline__step___bg4CE";
export var timeline__subtitle = "timelineDesktop-module__timeline__subtitle___vQVUc";
export var timeline__text = "timelineDesktop-module__timeline__text___mAepu";
export var timeline__timeline_dot = "timelineDesktop-module__timeline__timeline_dot___z4Izx";
export var timeline__timeline_item = "timelineDesktop-module__timeline__timeline_item___elrTV";
export var timeline__timeline_item__card = "timelineDesktop-module__timeline__timeline_item__card___wb6il";
export var timeline__timeline_item__timeline_content = "timelineDesktop-module__timeline__timeline_item__timeline_content___OFJIu";
export var timeline__title = "timelineDesktop-module__timeline__title___LX-Lo";
export var title = "timelineDesktop-module__title___WMGnZ";
export var typography_h1 = "timelineDesktop-module__typography_h1___mwkhI";
export var typography_h2 = "timelineDesktop-module__typography_h2___imzwa";
export var typography_h3 = "timelineDesktop-module__typography_h3___FcBf1";
export var white = "#fff";
export var white_button = "timelineDesktop-module__white_button___o7Xxj";
export var xl = "1536px";
export var xxl = "2500px";