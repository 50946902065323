import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as styles from './timelineDesktop.module.scss';

const TimelineDesktop = ({ intl: { formatMessage }, data }) => {
  return (
    <section>
      <div className={styles.timeline}>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <p className={styles.timeline__step}>1.</p>
            <div className={styles.timeline__img}>{data[0].image}</div>
          </div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: data[0].title })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: data[0].content })}
            </small>
          </div>
          <div className={styles.timeline__timeline_dot}></div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: data[1].title })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: data[1].content })}
            </small>
          </div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <div className={styles.timeline__img}>{data[1].image}</div>
            <p className={styles.timeline__step}>2.</p>
          </div>
          <div className={styles.timeline__timeline_dot}></div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <p className={styles.timeline__step}>3.</p>
            <div className={styles.timeline__img}>{data[2].image}</div>
          </div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: data[2].title })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: data[2].content })}
            </small>
          </div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: data[3].title })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: data[3].content })}
            </small>
          </div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <div className={styles.timeline__img}>{data[3].image}</div>
            <p className={styles.timeline__step}>4.</p>
          </div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <p className={styles.timeline__step}>5.</p>
            <div className={styles.timeline__img}>{data[4].image}</div>
          </div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: data[4].title })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: data[4].content })}
            </small>
          </div>
        </div>
        <div className={styles.timeline__timeline_item}>
          <div className={styles.timeline__timeline_dot}></div>
          <div className={styles.timeline__timeline_item__card}>
            <p className={styles.timeline__title}>
              {formatMessage({ id: data[5].title })}
            </p>
            <small className={styles.timeline__text}>
              {formatMessage({ id: data[5].content })}
            </small>
          </div>
          <div className={styles.timeline__timeline_item__timeline_content}>
            <div className={styles.timeline__img}>{data[5].image}</div>
            <p className={styles.timeline__step}>6.</p>
          </div>
        </div>
      </div>
    </section>
  );
};
TimelineDesktop.propTypes = {
  intl: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default injectIntl(TimelineDesktop);
